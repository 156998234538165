<template>
  <div
    :key="'form-group-' + placeholder"
    class="_form-group floating"
    :class="{ '_form-group--error': model && model.$error, '-sticky': sticky }"
  >
    <div :class="['relative input-model', hideAsteriskClass]">
      <select
        v-if="type === 'select' && model"
        :id="selectId"
        v-model="model.$model"
        :required="model.required"
        :placeholder="placeholder"
        class="_form-control"
        :class="inputClass"
        :data-cy="kebabCase(dataCy)"
      >
        <slot />
      </select>
      <input
        v-else-if="model"
        :type="getType()"
        :value="model.$model"
        :masked="false"
        :required="model.required"
        :placeholder="placeholder"
        :minlength="(model.minLength || {})?.min"
        class="_form-control"
        :class="inputClass"
        :data-cy="kebabCase(dataCy)"
        autocomplete="new-password"
        :disabled="disabled"
        @[changeEvent]="model.$model = $event.target.value"
        @blur="updateChangeEvent, (focus = false)"
        @focus="updateChangeEvent, (focus = true)"
      />

      <i
        v-if="type === 'password'"
        class="cursor-pointer icon77 absolute top-1/2 right-3 transform -translate-y-1/2"
        :class="[viewPassword ? 'icon77-eye-off' : 'icon77-eye']"
        @click="viewPassword = !viewPassword"
      />

      <i
        v-if="icon"
        class="icon77shop absolute top-1/2 right-3 transform -translate-y-1/2"
        :class="[icon, { 'cursor-pointer': $emit('icon-click') }]"
        @click="$emit('iconClick')"
      />
    </div>

    <label :class="{ 'focused-input': focus || (model && model.$model) }">
      {{ label || placeholder }}{{ model && model.required && '*' }}
    </label>

    <p v-if="caption && (!model || !model.$error)" class="body-12-aa mt-2 text-Grey-900">
      {{ caption }}
    </p>

    <!-- Errors handler -->
    <div v-if="model && model.$error && showError" class="_text-danger">
      <p v-if="model?.required?.$message && model?.required?.$params?.type === 'required' && model?.required?.$invalid">
        {{ label || placeholder }} {{ $t('is-required') }}
      </p>
      <p v-else-if="model.minLength">
        {{ label || placeholder }} {{ $t('must-at-least') }}
        {{ model?.minLength?.min || model?.minLength?.$params?.min }} {{ $t('letters') }}.
      </p>
      <p v-else-if="model.email && !model.email">{{ label || placeholder }} {{ $t('must-be-valid') }}</p>
      <p v-else-if="model.nonRoman && !model.nonRoman">
        {{ $t('use-latin-characters', 'Please use Latin characters instead') }}
      </p>
      <p v-else-if="model.password && !model.password">
        {{
          $t(
            'password-must-be-valid',
            'Password must be at least 8 characters long, contain at least one number, one uppercase and one lowercase letter, and one special character.',
          )
        }}
      </p>
      <p v-else>{{ label || placeholder }} {{ $t('must-be-valid') }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  type: { type: String },
  mask: String,
  forceUpdate: Boolean,
  label: String,
  placeholder: String,
  sticky: Boolean,
  inputClass: String,
  selectId: String,
  dataCy: String,
  caption: String,
  icon: String,
  disabled: Boolean,
  showError: { type: Boolean, default: true },
  showAsterisk: { type: Boolean, default: true },
})

import { browserIsSafari } from '~/utils/browser'

const model = defineModel()

const changeEvent = ref(browserIsSafari() ? 'input' : 'change')
const viewPassword = ref(false)
const focus = ref(false)

const hideAsteriskClass = computed(() => (props.showAsterisk ? '' : 'hide-asterisk'))

const kebabCase = (str) => str && str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

const updateChangeEvent = () => {
  changeEvent.value = model.value?.$error || props.forceUpdate || browserIsSafari() ? 'input' : 'change'
}

const getType = () => {
  if (props.type) {
    if (props.type === 'password') {
      return viewPassword.value ? 'text' : 'password'
    }
    return props.type
  }
  return model.value.$params?.email ? 'email' : 'text'
}
</script>

<style lang="scss" scoped>
.hide-asterisk + .focused-input {
  display: none;
}
</style>
